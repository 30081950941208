import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Warehouse from './Warehouse'
import Services from './Services'
import GoogleMap from './GoogleMap'
import NavBar from 'components/NavBar'
import Layout from 'components/Layout'

export const WarehousingPageTemplate = ({
  header,
  section1,
  section2,
  section3
}) => (
  <Fragment>
    <Header
      images={header.images}
      number={header.number}
      title={header.title}
      subtitle={header.subtitle}
      anchor={header.scrollAnchor}
    />
    <Services
      title={section1.title}
      text={section1.text}
      services={section1.services}
      button={{
        path: section1.buttonPath,
        text: section1.buttonText
      }}
      anchor={header.scrollAnchor}
      title2={section2.title}
      text2={section2.text}
    />
    <Warehouse
      title={section3.locations[0].title}
      subtitle={section3.locations[0].subtitle}
      text={section3.locations[0].text}
      image={section3.locations[0].image.childImageSharp}
    />
    <Warehouse
      reverse="reverse"
      title={section3.locations[1].title}
      subtitle={section3.locations[1].subtitle}
      text={section3.locations[1].text}
      image={section3.locations[1].image.childImageSharp}
    />
    <Warehouse
      title={section3.locations[2].title}
      subtitle={section3.locations[2].subtitle}
      text={section3.locations[2].text}
      image={section3.locations[2].image.childImageSharp}
    />
    {section3.mapMarker && (
      <GoogleMap
        mapMarker={section3.mapMarker.childImageSharp.sizes.src}
        coordinates={section3.locations}
      />
    )}
  </Fragment>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, structuredData } = frontmatter
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
        structuredData={structuredData}
      />
      <Helmet title={seo.title} />
      <NavBar />
      <WarehousingPageTemplate
        header={frontmatter.header}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
        section3={frontmatter.section3}
      />
    </Layout>
  )
}

export const query = graphql`
  query WarehousingPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seo {
          title
          description
          url
        }
        structuredData {
          context
          type
          url
          name
          address
          telephone
          priceRange
          additionalProperty
          openingHours
          areaServed
          email
          legalName
          alternateName
          description
        }
        header {
          images {
            mobile {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  src
                  srcSet
                }
              }
            }
            desktop {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          number
          title
          subtitle
          scrollAnchor
        }
        section1 {
          title
          text
          services {
            name
          }
          buttonPath
          buttonText
        }
        section2 {
          title
          text
        }
        section3 {
          mapMarker {
            childImageSharp {
              sizes(maxWidth: 2200) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          locations {
            title
            subtitle
            text
            image {
              childImageSharp {
                sizes(maxWidth: 2200) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            lat
            lon
          }
        }
      }
    }
  }
`
