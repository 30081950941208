import React from 'react'
import styled from 'styled-components'

import ScrollAnchor from 'components/ScrollAnchor'

export default ({ title, text, anchor, color }) => (
  <ScrollAnchor anchor={anchor}>
    <Container bgColor={color}>
      <Heading>{title}</Heading>
      <Text>{text}</Text>
    </Container>
  </ScrollAnchor>
)
const Container = styled.div`
  width: 100%;
  padding: 50px 0;
  background-color: ${props => props.bgColor || '#f5f5f5'};
`
const Heading = styled.h2`
  margin: 30px auto;
  font-size: 45pt;
  width: 55%;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
  text-align: center;

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 30pt;
    width: 85%;
  }
`
const Text = styled.p`
  margin: 0 auto 24pt;
  font-size: 22px;
  line-height: 24pt;
  width: 70%;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.bold};
  text-align: center;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 85%;
    font-size: 18px;
  }
`
