import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default ({ url, children }) => (
  <ButtonContainer to={url}>
    <ButtonText>{children}</ButtonText>
    <ButtonHoverBG />
  </ButtonContainer>
)
const ButtonContainer = styled(Link)`
  display: inline-block;
  height: 60px;
  margin: 15px 0;
  overflow: hidden;
  border: 3px solid ${props => props.theme.primaryColor};
  background-color: ${props => props.theme.primaryColor};
  cursor: pointer;
  box-sizing: content-box;
  border-radius: 5px;
`
const ButtonText = styled.h2`
  font-size: 14.5pt;
  font-weight: ${props => props.theme.demi};
  line-height: 5px;
  padding: 30px 50px;
  color: white;
  margin: 0 auto;
  position: relative;
  transition-duration: 0.5s;
  z-index: 100;
  text-transform: uppercase;
  cursor: pointer;
  ${/* sc-selector */ ButtonContainer}:hover & {
    color: ${props => props.theme.primaryColor};
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 12pt;
    padding: 30px 20px;
    ${/* sc-selector */ ButtonContainer}:hover & {
      color: white;
    }
  }
`
const ButtonHoverBG = styled.div`
  position: relative;
  top: -65px;
  left: -20px;
  height: calc(100% + 8px);
  width: 0;
  background-color: white;
  transition-duration: 0.5s;
  transform: skewX(-30deg);
  z-index: 50;
  cursor: pointer;
  ${/* sc-selector */ ButtonContainer}:hover & {
    width: calc(100% + 80px);
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    ${/* sc-selector */ ButtonContainer}:hover & {
      width: 0;
    }
  }
`
