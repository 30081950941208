import React from 'react'
import styled from 'styled-components'

import GatsbyImageLoader from 'components/GatsbyImageLoader'

export default ({ image, title, subtitle, text, reverse }) => (
  <Section reverse={reverse}>
    <InfoContainer reverse={reverse}>
      <Heading>{title}</Heading>
      <SubHeading>{subtitle}</SubHeading>
      <Text>{text}</Text>
    </InfoContainer>
    <GatsbyImageLoader sizes={image.sizes}>
      {({ src }) => <ImageDiv image={src} />}
    </GatsbyImageLoader>
  </Section>
)
const Section = styled.section`
  margin: 8px 0;
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: ${props =>
    props.reverse === 'reverse' ? 'row-reverse' : 'flex-row'};
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    flex-direction: column;
    height: auto;
  }
`
const InfoContainer = styled.div`
  height: 100%;
  padding: ${props =>
    props.reverse === 'reverse'
      ? '100px 100px 100px 180px'
      : '100px 180px 100px 100px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props =>
    props.reverse === 'reverse' ? 'flex-end' : 'flex-start'};
  text-align: ${props => (props.reverse === 'reverse' ? 'right' : 'left')};
  width: 50%;
  background-color: #f5f5f5;

  @media (max-width: ${props => props.theme.xl}px) {
    padding: ${props =>
      props.reverse === 'reverse'
        ? '40px 50px 40px 80px'
        : '40px 50px 40px 80px'};
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
    padding: ${props =>
      props.reverse === 'reverse'
        ? '90px 100px 90px 200px'
        : '90px 200px 90px 100px'};
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    padding: 50px 30px;
  }
`
const Heading = styled.h1`
  font-size: 45pt;
  width: 100%;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
  text-align: inherit;

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 40pt;
  }
`
const SubHeading = styled.h2`
  margin: 30px 0;
  font-size: 18pt;
  width: 100%;
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.demi};
  text-align: inherit;
`
const Text = styled.p`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 16pt;
  width: 100%;
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.demi};
  text-align: inherit;
`
const ImageDiv = styled.div`
  margin: 0;
  width: 50%;
  height: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
    height: 400px;
  }
`
