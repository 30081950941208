import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { TweenMax, TimelineMax } from 'gsap'
import { ReactComponent as Arrow } from 'assets/arrow.svg'
import { Link } from 'react-scroll'

import GatsbyImageLoader from 'components/GatsbyImageLoader'

export default class Header extends Component {
  componentDidMount = () => {
    animate.animateArrow(this.arrowRef)
  }

  render() {
    const { images, number, title, subtitle, anchor } = this.props
    return (
      <Fragment>
        <GatsbyImageLoader sizes={images.mobile.childImageSharp.sizes}>
          {({ src: mobileImg }) => (
            <GatsbyImageLoader sizes={images.desktop.childImageSharp.sizes}>
              {({ src: desktopImg }) => (
                <Background image={desktopImg} mobileImage={mobileImg} />
              )}
            </GatsbyImageLoader>
          )}
        </GatsbyImageLoader>
        {number ? (
          <Container number>
            <Number>{number}</Number>
            <Heading number>{title}</Heading>
            <Subtitle number>{subtitle}</Subtitle>
          </Container>
        ) : (
          <Container>
            <Heading>{title}</Heading>
            <Divider />
            <Subtitle>{subtitle}</Subtitle>
          </Container>
        )}
        <ArrowContainer to={anchor} offset={-70} smooth={true}>
          <div
            ref={x => {
              this.arrowRef = x
            }}
          >
            <DownArrow />
          </div>
        </ArrowContainer>
      </Fragment>
    )
  }
}

const animate = {
  /*********** animate scroll arrow ***********/
  animateArrow(arrowRef) {
    const tl = new TimelineMax({ repeat: -1 })
    let arrow = arrowRef.firstChild

    tl.add(TweenMax.to(arrow, 0.8, { marginBottom: 0 }))
    tl.add(TweenMax.to(arrow, 0.6, { opacity: 0 }))
    tl.add(TweenMax.to(arrow, 0.2, { marginBottom: 20 }))
    tl.add(TweenMax.to(arrow, 0.8, { opacity: 1 }))
  }
}

const Background = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(1) contrast(1) brightness(0.5);
  z-index: 1;
  transition-duration: 0.5s;

  @media (max-width: ${props => props.theme.phone}px) {
    background-image: url(${props => props.mobileImage});
  }
`
const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.number ? 'flex-start' : 'center')};
  z-index: 2;

  @media (max-width: ${props => props.theme.tablet}px) {
    padding: ${props => (props.number ? '90px 70px 50px' : '90px 40px 50px')};
  }

  @media (max-width: ${props => props.theme.phone}px) {
    padding: ${props => (props.number ? '90px 30px 50px' : '90px 20px 50px')};
  }
`
const Number = styled.h1`
  margin-left: 7px;
  color: white;
  font-size: 60pt;
  font-weight: ${props => props.theme.demi};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 45pt;
  }
`
const Heading = styled.h1`
  color: white;
  font-size: ${props => (props.number ? 110 : 70)}pt;
  width: 80%;
  letter-spacing: -3px;
  text-align: ${props => (props.number ? 'left' : 'center')};
  font-weight: ${props => props.theme.ultra};

  @media (max-width: ${props => props.theme.xl}px) {
    font-size: ${props => (props.number ? 110 : 65)}pt;
    width: 90%;
  }

  @media (max-width: 1050px) {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: ${props => (props.number ? 110 : 45)}pt;
    letter-spacing: -1px;
    width: 100%;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: ${props => (props.number ? 80 : 45)}pt;
    letter-spacing: none;
    width: 100%;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    font-size: ${props => (props.number ? 80 : 40)}pt;
    letter-spacing: none;
    width: 100%;
  }
`
const Divider = styled.div`
  margin: 30px 0 50px;
  width: 150px;
  min-height: 8px;
  background-color: white;

  @media (max-width: ${props => props.theme.phone}px) {
    margin: 20px 0 30px;
    width: 90px;
    height: 4px;
  }
`
const Subtitle = styled.h2`
  margin-left: ${props => (props.number ? '7px' : null)};
  color: white;
  font-size: 20pt;
  text-align: ${props => (props.number ? 'left' : 'center')};
  font-weight: ${props => props.theme.demi};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 18pt;
    margin-bottom: 100px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    margin-bottom: 50px;
  }
`
const ArrowContainer = styled(Link)`
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 50px);
  cursor: pointer;
  z-index: 100;
`
const DownArrow = styled(Arrow)`
  transform: scale(2);
  width: 22px;
  margin-bottom: 20px;
`
