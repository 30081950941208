import React, { Fragment } from 'react'
import styled from 'styled-components'

import { ReactComponent as CheckMark } from 'assets/green-check.svg'

import FullWidthSection from 'components/FullWidthSection'
import Button from 'components/Button'

export default ({ title, text, services, button, anchor, title2, text2 }) => (
  <Fragment>
    <FullWidthSection title={title} text={text} anchor={anchor} />
    <Container>
      <CardContainer>
        {services.map((service, i) => {
          return (
            <Card key={i}>
              <Icon />
              <CardText>{service.name}</CardText>
            </Card>
          )
        })}
      </CardContainer>
    </Container>
    <ButtonContainer>
      <Button url={button.path}>{button.text}</Button>
    </ButtonContainer>
    <CopyContainer>
      <Heading>{title2}</Heading>
      <Text>{text2}</Text>
    </CopyContainer>
  </Fragment>
)
const Container = styled.div`
  width: 100%;
  background-color: #f5f5f5;
`
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 890px;
  background-color: #f5f5f5;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
  }
`
const Card = styled.div`
  margin: 10px;
  padding: 15px;
  width: 200px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 400px;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 320px;
  }
`
const Icon = styled(CheckMark)`
  width: 35px;
  height: 35px;
  margin: 15px 15px 20px;

  @media (max-width: ${props => props.theme.desktop}px) {
    height: 30px;
    width: 30px;
    margin: 15px;
  }
`
const CardText = styled.p`
  font-size: 14pt;
  line-height: 18pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.bold};
  text-align: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    text-align: left;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
`
const CopyContainer = styled.div`
  width: 100%;
  padding: 50px 0;
`
const Heading = styled.h1`
  margin: 30px auto;
  font-size: 45pt;
  width: 75%;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
  text-align: center;

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 30pt;
    width: 85%;
  }
`
const Text = styled.p`
  margin: 0 auto 24pt;
  font-size: 22px;
  line-height: 24pt;
  width: 70%;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.bold};
  text-align: center;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 85%;
    font-size: 18px;
  }
`
