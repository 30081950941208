import React, { Component, Fragment } from 'react'
import { Element } from 'react-scroll'

export default class ScrollAnchor extends Component {
  render() {
    const { anchor, children } = this.props
    return (
      <Fragment>
        {typeof anchor === 'string' ? (
          <Element offset={-70} duration={1000} name={anchor}>
            {children}
          </Element>
        ) : (
          <Fragment>{children}</Fragment>
        )}
      </Fragment>
    )
  }
}
