import React, { Component } from 'react'
import styled from 'styled-components'

import Map, {
  GoogleApiWrapper,
  InfoWindow,
  Marker
} from 'components/GoogleMaps'

const mapStyles = {
  container: {
    position: 'unset',
    width: '100%',
    height: '400px'
  },
  map: {
    // position: '',
    // left: '',
    // right: '',
    // bottom: '',
    // top: ''
  }
}

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    zoom: 11
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 950) {
        this.setState({ zoom: 10 })
      }
    }
  }

  render() {
    const MapDiv = styled.div`
      position: relative;
      width: 100%;
    `
    const { zoom } = this.state
    const { google, className, mapMarker, coordinates } = this.props
    return (
      <MapDiv>
        <div className={className}>
          <Map
            google={google}
            zoom={zoom}
            containerStyle={mapStyles.container}
            style={mapStyles.map}
            initialCenter={{
              lng: -94.293744,
              lat: 36.35083
            }}
          >
            {coordinates.map((item, i) => {
              return (
                <Marker
                  key={i}
                  onClick={this.onMarkerClick}
                  name={'Current location'}
                  position={{
                    lng: item.lon,
                    lat: item.lat
                  }}
                  icon={{
                    url: mapMarker,
                    anchor: google ? new google.maps.Point(40, 40) : null,
                    scaledSize: google ? new google.maps.Size(80, 80) : null
                  }}
                />
              )
            })}
            <InfoWindow onClose={this.onInfoWindowClose}>
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div>
            </InfoWindow>
          </Map>
        </div>
      </MapDiv>
    )
  }
}

export default (typeof window !== 'undefined'
  ? GoogleApiWrapper({
      apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY
    })(MapContainer)
  : () => <div />)
